import React, { FC, useCallback, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { IReviewsQuery } from '../../../../queries/reviews/types';
import { ICaseQuery } from '../../../../queries/cases/types';
import { IHeaderQuery } from '../../../../queries/headers/types';
import { IotTitle } from '../../../../components/directions/iot/screens/IotTitle';
import IotSolutions from '../../../../components/directions/iot/screens/IotSolutions';
import IotPrinciples from '../../../../components/directions/iot/screens/IotPrinciples';
import { IotWhenOrder } from '../../../../components/directions/iot/screens/IotWhenOrder';
import { IotForm } from '../../../../components/directions/iot/IotForm';
import { KmmSuccessModal } from '../../../../components/directions/kmm/screens/KmmSuccessModal';
import IotWhyUs from '../../../../components/directions/iot/screens/IotWhyUs';
import IotFaq from '../../../../components/directions/iot/screens/IotFaq';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery & IReviewsQuery & IHeaderQuery & ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const IotPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'ru' },
}) => {
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const captchaOneId = 'captchaOne';
  const captchaTwoId = 'captchaTwo';

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );

  const title = direction?.node.frontmatter.name ?? '';

  return (
    <ReCaptchaProvider>
      <MainLayout locale={locale} title={title}>
        <IotTitle title={direction?.node.frontmatter.name ?? ''} />
        <IotSolutions />
        <IotPrinciples />
        <IotWhenOrder />
        <IotForm id={captchaOneId} />
        <IotWhyUs />
        <IotFaq />
        <IotForm id={captchaTwoId} />

        {isSuccessShown && <KmmSuccessModal onClose={toggleSuccess} />}
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/header/header/" } }
    ) {
      ...HeaderFields
    }

    reviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/reviews/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...ReviewFields
    }
  }
`;

export default IotPage;
