import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { sendIotRequest } from '../../../../utils/api';
import { OopsPanel } from '../../../common/OopsPanel';
import { InternshipSuccessModal } from '../../internship/components/InternshipSuccessModal';
import { InternshipTextInput } from '../../internship/components/InternshipTextInput';
import { t } from '../../../../i18n';
import { Button } from '../../../main/Button';

interface IProps {
  id: string;
}

const IotForm: FC<IProps> = ({ id }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [requestType, setRequestType] = useState('default');

  const [disabled, setDisabled] = useState(false);

  const options = [
    { value: 'wantNewApp', label: 'заказ нового приложения' },
    { value: 'wantUpdateApp', label: 'обновить мое приложение' },
    { value: 'wantMVP', label: 'получить MVP' },
  ];

  const validateFields = useCallback(() => {
    const errorsField = {
      name: !name || name.length <= 2,
      phone: !phone || phone.length <= 10,
      requestType: requestType === 'default',
    };

    setErrors(errorsField);
    return !Object.values(errorsField).some((val) => val);
  }, [name, phone, requestType, setErrors]);

  const [success, setSuccess] = useState(false);
  const [showOops, setShowOops] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const clearFields = useCallback(() => {
    setPhone('+7');
    setName('');
    setRequestType('default');
  }, [setName, setPhone, setRequestType, setRequestType]);

  const removeError = (
    fieldName: string,
    prevValue: string,
    curValue: string
  ) => {
    if (prevValue !== curValue) {
      const errorsTemp = { ...errors, [fieldName]: false };
      setErrors(errorsTemp);
    }
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setDisabled(true);

      try {
        if (!validateFields()) return;
        if (!executeRecaptcha) return;
        const captcha = await executeRecaptcha(id);

        await sendIotRequest({
          name,
          phone,
          requestType,
          agreeOnProcessingPersonalData: true,
          captcha,
        });

        setSuccess(true);
        clearFields();
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.response?.data.error);
        setShowOops(true);
      } finally {
        setDisabled(false);
      }
    },
    [name, phone, requestType, validateFields]
  );

  useEffect(() => {
    if (showOops) {
      setShowOops(false);
    }
  }, [name, phone, requestType]);

  const closeSuccessModal = useCallback(() => setSuccess(false), []);
  const iotFormPrefix = 'cases.iot.form';

  return (
    <>
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />
      <div className={styles.wrap}>
        <form
          className={classnames('content', styles.form)}
          onSubmit={onSubmit}
          id="iot-form"
        >
          {success && <InternshipSuccessModal onClose={closeSuccessModal} />}
          <div className={styles.title}>{t(`${iotFormPrefix}.title`)}</div>

          <div className={styles.inputs}>
            <InternshipTextInput
              value={name}
              name="name"
              handler={setName}
              label={`${iotFormPrefix}.name`}
              error={errors.name ? 'form.field_required' : ''}
              icon="/case/internship/form/name.svg"
              removeError={removeError}
            />

            <InternshipTextInput
              value={phone}
              name="phone"
              handler={setPhone}
              error={errors.phone ? 'form.field_required' : ''}
              icon="/case/internship/form/email.svg"
              removeError={removeError}
            />
          </div>

          <div className={styles.selectWrapper}>
            <select
              value={requestType}
              onChange={(e) => {
                setRequestType(e.target.value);
                if (e.target.value !== 'default') {
                  const errorsTemp = { ...errors, requestType: false };
                  setErrors(errorsTemp);
                }
              }}
              className={`
            ${styles.select}
            ${requestType === 'default' ? styles.select__placeholder : ''}
            ${errors.requestType ? styles.select__error : ''}
           `}
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundImage: "url('/case/internship/buttons/close.svg')",
                backgroundColor: '#F4F3F8',
                backgroundPosition: '98.5% 50%',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
              }}
            >
              <option value="default" disabled hidden>
                Тип обращения
              </option>
              {options.map((optionLocal) => (
                <option key={optionLocal.value} value={optionLocal.value}>
                  {optionLocal.label}
                </option>
              ))}
            </select>
            {errors.requestType && (
              <div className={styles.error}>
                {t('contacts.fill_this_field')}
              </div>
            )}
          </div>

          <div className={styles.submit}>
            <button type="submit" className={styles.buttonMobile}>
              {t(`${iotFormPrefix}.button`)}
            </button>
            <div className={styles.agreeWrap}>
              <div className={styles.agree}>
                {t(`${iotFormPrefix}.agree.text`)}
                <div className={styles.agree__link}>
                  <a href="/case/restocare/terms.pdf">
                    {t(`${iotFormPrefix}.agree.link`)}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Button
            className={styles.button}
            isLoading={disabled}
            disabled={disabled}
          >
            {t(`${iotFormPrefix}.button`)}
          </Button>
        </form>
      </div>
    </>
  );
};

export { IotForm };
